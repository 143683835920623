<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Año"
          item-text="fechaAnual"
          item-value="id"
          :items="anual"
          v-model="anualId"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Grupo"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Estudiante"
          item-text="nombre"
          item-value="id"
          :items="estudiantesFiltro"
          v-model="estudianteId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" align-content="center">
      <v-col>
        <v-data-table
          style="max-width: 1000px"
          :headers="headers"
          :items="tablaEstudiantesFiltro"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.apellidos`]="{ item }">
            <div>
              <v-text-field
                counter
                maxlength="30"
                v-model="item.apellidos"
                @change="actualizarNombre(item)"
              >
              </v-text-field>
            </div>
          </template>
          <template v-slot:[`item.nombres`]="{ item }">
            <div>
              <v-text-field
                counter
                maxlength="30"
                v-model="item.nombres"
                @change="actualizarNombre(item)"
              >
              </v-text-field>
            </div>
          </template>
          <template v-slot:[`item.tipoIdentificacion`]="{ item }">
            <div>
              <v-text-field
                counter
                maxlength="30"
                v-model="item.tipoIdentificacion"
                @change="actualizarNombre(item)"
              >
              </v-text-field>
            </div>
          </template>
          <template v-slot:[`item.identificacion`]="{ item }">
            <div>
              <v-text-field
                counter
                maxlength="30"
                v-model="item.identificacion"
                @change="actualizarNombre(item)"
              >
              </v-text-field>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    grupos: [],
    grupoId: 0,
    anualId: 0,
    estudianteId: "0",
    estudiantes: [],
    headers: [
      { text: "Apellidos", value: "apellidos" },
      { text: "Nombres", value: "nombres" },
      { text: "Tipo documento" , value:"tipoIdentificacion"},
      { text: "Identificación" , value:"identificacion"}
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["anual"]),
    estudiantesFiltro() {
      let estudiantes = [];
      estudiantes.push({
        id: "0",
        nombre: "Todos",
      });

      estudiantes.push(
        ...this.estudiantes.map((x) => {
          return { id: x.estudianteId, nombre: x.apellidos + " " + x.nombres };
        })
      );

      return estudiantes;
    },
    tablaEstudiantesFiltro() {
      if (this.estudianteId !== "0") {
        return this.estudiantes.filter((x) => {
          return x.estudianteId === this.estudianteId;
        });
      } else {
        return this.estudiantes;
      }
    },
  },
  methods: {
    ...mapActions([
      "GetAnuales",
      "GetGrupos",
      "GetAlumnosGrupo",
      "UpdateEstudiante",
      "ModificarMensaje",
    ]),
    initialize() {
      this.GetAnuales();
      this.GetGrupos().then(
          (x) => (this.grupos = x.data)
        );
    },
    actualizarNombre(item) {
      let datos = {};
      datos.id = item.estudianteId;
      datos.nombres = item.nombres;
      datos.apellidos = item.apellidos;
      datos.tipoIdentificacion = item.tipoIdentificacion;
      datos.identificacion = item.identificacion;
      this.UpdateEstudiante(datos).then((x) =>
        this.ModificarMensaje({ texto: "Registro Exitoso!" })
      );
    },
  },
  watch: {
    grupoId(nuevoValor) {
      let datos = {};
      datos.grupo = nuevoValor;
      datos.anual =  this.anualId;
      this.GetAlumnosGrupo(datos).then((x) => (this.estudiantes = x.data));
    },
  },
};
</script>